<template>
<div class="library">
    <Document v-if='window' :doc="{src:window.location.origin+'/Ma_Copro_ONVous_donne_les_clefs_CGU_20210215.pdf', title:'Conditions générales d\'Utilisation'}"  :closable="false"/>

</div>
</template>
<script>
import Document from '@/components/Library_Document.vue'
export default {
components:{Document},
data(){
    return {
        window:null
    }
}, 
mounted(){
    this.window = window
} 
}
</script>
